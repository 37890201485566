import React, { useEffect } from 'react';
import './App.css'


export const AdRightBanner: React.FC = () => {
  useEffect(() => {   
      const p: any = {};
      try {
        if (typeof window === 'object') {
          ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(p);
        }
      } catch {
        // Pass
      }
  }, []);

  return (
    <div className="absolute z-0  hidden xl:flex lg:flex right-0 top-4 translate-x-full  flex-col">    
        <div className='bg-black/10' 
        style={{minWidth: 300, minHeight:200}}
        >
<ins className="adsbygoogle block"     
     data-ad-client="ca-pub-4209061042216624"
     data-ad-slot="9783675696"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>

        </div>
     </div>
  );
};

const AdBanner: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      window.googletag = window.googletag || { cmd: [] };

      window.googletag.cmd.push(function () {
        
        const sizes: any[] =  (window.innerWidth > 768) ? [[320,100], [728,90]] : [[320,100]];
        window.googletag.pubads().set("page_url", 'hoopgrids.com');
 
        const adSlot = window.googletag.defineSlot('/15567680/2023-hoopgrids-adsense', sizes, 'div-gpt-ad-1691463611289-0');

        adSlot!.addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display('div-gpt-ad-1691463611289-0');
      });
    };
  }, []);

  return (
    <>
    <div className="absolute flex flex-col w-full justify-center items-center mt-4" style={{
      overflow:'hidden',
      width:'100%',
      zIndex: -1,
      minHeight: '90px',
      left:'0', 
      maxWidth: '100vw'
    }}> 
        <div
          id="div-gpt-ad-1691463611289-0"
          className='ad-banner'
          style={{
            minWidth: '300px',
            minHeight: '50px',
            paddingTop: '10px',
            paddingBottom: '40px',
          }}
        ></div>
      </div>
    <div className="relative w-full flex flex-col h-[140px]" />
    </>
  );
};

export default AdBanner;
