import React, { useState } from "react";
import "./grid.css"
import { collegeLinks, logoLinks } from "./logoLinks";
import { Labels } from "./getData";
import { NullablePlayer } from "./App";
import { NullableNumberList } from "./App";

interface Props {
    labels: Labels;
    getSearch(): void;
    getSelectedGrid(gridNumber: number): void;
    players: NullablePlayer;
    votes: NullableNumberList;
}
function getbackgroundColor(votes: number | null) {

    console.log("votes:", votes)

    if (votes === null) {

        return "#36454F"
    }
    else if (votes < .1) {


        return 'radial-gradient(ellipse 80% 45%, #d42dab 1%, #d42dab 10%, #4d1d63)'

    }
    else if (votes < 1) {


        return 'linear-gradient(to bottom right, #00f6ff, #ddb6fc, #00f6ff)'

    }
    else if (votes < 5) {



        return 'linear-gradient(to bottom right, #e6bc35, #fff3d4, #e6bc35)'


    }
    else if (votes < 10) {


        return 'linear-gradient(to bottom right, #a19f9f, white, #a19f9f)'


    }
    else if (votes < 15) {


        return 'linear-gradient(to bottom right, #ad8a58, #faeede ,#ad8a58)'

    }

}

export const Grid: React.FC<Props> = ({ labels, getSearch, getSelectedGrid, players, votes }) => {


    const sum = votes.reduce((accumulator: number, currentValue: number | null) => {
        if (currentValue !== null) {
            return accumulator + currentValue;
        } else {
            return accumulator;
        }
    }, 0);

    return (
        <div className="grid-container" style={{ whiteSpace: 'pre-line' }}>

            <div className="grid-item-first">
                <h5>Rarity Score:</h5>
                <h4>{sum.toFixed(2)}</h4>
            </div>

            <div className="grid-item-outer">
                <img src={logoLinks[labels.teams[0] as keyof typeof logoLinks]} />
            </div>

            <div className="grid-item-outer">
                {labels.stats_col2 !== null ? (<p>{labels.stats_col2.text}</p>) : labels.awards_col2 !== null ? (<p>{labels.awards_col2.text}</p>) : (<img src={logoLinks[labels.teams[1] as keyof typeof logoLinks]} />)}
            </div>

            <div className="grid-item-outer">
                <p>{labels.stats?.text}</p>
                <p>{labels.awards?.text}</p>
            </div>

            <div className="grid-item-outer">
                <img src={logoLinks[labels.teams[2] as keyof typeof logoLinks]} />
            </div>

            {players[0] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(0)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[0]) }}>
                        <img src={players[0].image_link} />
                        <div className="centered">{players[0].name}</div>
                        {votes[0] !== null ? <div className="top">{votes[0]}%</div> : null}

                    </div></button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(0)
                }}>
                    <div className="grid-item"></div></button>}

            {players[1] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(1)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[1]) }}>
                        <img src={players[1].image_link} />
                        <div className="centered">{players[1].name}</div>
                        {votes[1] !== null ? <div className="top">{votes[1]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(1)
                }}>
                    <div className="grid-item"></div></button>}


            {players[2] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(2)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[2]) }}>
                        <img src={players[2].image_link} />
                        <div className="centered">{players[2].name}</div>
                        {votes[2] !== null ? <div className="top">{votes[2]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(2)
                }}>
                    <div className="grid-item"></div></button>}

            <div className="grid-item-outer">
                <img src={logoLinks[labels.teams[3] as keyof typeof logoLinks]} />
            </div>

            {players[3] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(3)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[3]) }}>
                        <img src={players[3].image_link} />
                        <div className="centered">{players[3].name}</div>
                        {votes[3] !== null ? <div className="top">{votes[3]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(3)
                }}>
                    <div className="grid-item"></div></button>}


            {players[4] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(4)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[4]) }}>
                        <img src={players[4].image_link} />
                        <div className="centered">{players[4].name}</div>
                        {votes[4] !== null ? <div className="top">{votes[4]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(4)
                }}>
                    <div className="grid-item"></div></button>}

            {players[5] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(5)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[5]) }}>
                        <img src={players[5].image_link} />
                        <div className="centered">{players[5].name}</div>
                        {votes[5] !== null ? <div className="top">{votes[5]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(5)
                }}>
                    <div className="grid-item"></div></button>}



            <div className="grid-item-outer">
                {labels.college !== null ? (
                    <img src={collegeLinks[labels.college.id as keyof typeof collegeLinks]} />
                ) : labels.teammate !== null ? (
                    <div>
                        <img className="teammate" src={'https://cdn.nba.com/headshots/nba/latest/260x190/' + labels.teammate.id + '.png'} />
                        <div className="text-teammate">Teammate</div>
                    </div>
                ) : labels.coach !== null ? (
                    <div>
                        <img className="teammate" src={'https://cdn.nba.com/headshots/nba/latest/260x190/' + labels.coach.id + '.png'} />
                        <div className="text-teammate">Coach</div>
                    </div>
                ) :
                    <img src={logoLinks[labels.teams[4] as keyof typeof logoLinks]} />
                }</div>



            {players[6] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(6)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[6]) }}>
                        <img src={players[6].image_link} />
                        <div className="centered">{players[6].name}</div>
                        {votes[6] !== null ? <div className="top">{votes[6]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(6)
                }}>
                    <div className="grid-item"></div></button>}

            {players[7] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(7)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[7]) }}>
                        <img src={players[7].image_link} />
                        <div className="centered">{players[7].name}</div>
                        {votes[7] !== null ? <div className="top">{votes[7]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(7)
                }}>
                    <div className="grid-item"></div></button>}

            {players[8] !== null ?
                (<button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(8)
                }}>
                    <div className="grid-item" style={{ background: getbackgroundColor(votes[8]) }}>
                        <img src={players[8].image_link} />
                        <div className="centered">{players[8].name}</div>
                        {votes[8] !== null ? <div className="top">{votes[8]}%</div> : null}
                    </div>
                </button>
                ) : <button id="myButton" onClick={() => {
                    getSearch()
                    getSelectedGrid(8)
                }}>
                    <div className="grid-item"></div></button>}

        </div>







    );
};