var grids = require("./grids.json");

interface Text {
    id: string;
    text: string;
}

export interface gridData {
    id: number;
    date: string;
    teams: number[];
    awards: number | null;
    stats: number | null;
    awards_col2: number | null;
    stats_col2: number | null;
    teammate: number | null;
    coach: number | null;
    college: number | null;
    index: number;
}

export interface Labels {
    id: number;
    teams: string[];
    awards: Text | null;
    stats: Text | null;
    awards_col2: Text | null;
    stats_col2: Text | null;
    college: Text | null;
    teammate: Text | null;
    coach: Text | null;
}

const listOfColleges: Text[] =
    [
        {
            id: "duke",
            text: "Duke"
        },
        {
            id: "kansas",
            text: "Kansas"
        },
        {
            id: "kentucky",
            text: "Kentucky"
        },
        {
            id: "unc",
            text: "North Carolina"
        }
    ]

const listOfTeammates: Text[] =
    [
        { // 0
            id: "2544",
            text: "LeBron James"
        },
        { // 1
            id: "1713",
            text: "Vince Carter"
        },
        { // 2
            id: "406",
            text: "Shaquille O'Neal"
        },
        { // 3
            id: "201142",
            text: "Kevin Durant"
        },
        { // 4
            id: "202397",
            text: "Ish Smith"
        },
        { // 5
            id: "2546",
            text: "Carmelo Anthony"
        },
        { // 5
            id: "959",
            text: "Steve Nash"
        },
        { // 6
            id: "2738",
            text: "Andre Iguodala"
        },
        { // 8
            id: "2730",
            text: "Dwight Howard"
        },
        { // 8
            id: "893",
            text: "Michael Jordan"
        },
        { // 8
            id: "977",
            text: "Kobe Bryant"
        }
    ]

const listOfCoaches: Text[] =
    [

        { // 0
            id: "1941",
            text: "Doc Rivers"
        },
        { // 1
            id: "202978",
            text: "Tyron Lue"
        }
    ]


const listOfTeams: string[] =
    [
        "bos", // 0
        "atl", // 1
        "bkn", // 2
        "cha", // 3
        "chi", // 4
        "cle", // 5
        "dal", // 6
        "den", // 7
        "det", // 8
        "gsw", // 9
        "hou", // 10
        "ind", // 11
        "lac", // 12
        "lal", // 13
        "mem", // 14
        "mia", // 15
        "mil", // 16
        "min", // 17
        "nop", // 18
        "nyk", // 19
        "okc", // 20
        "orl", // 21
        "phi", // 22
        "phx", // 23
        "por", // 24
        "sac", // 25
        "sas", // 26
        "tor", // 27
        "uta", // 28
        "was"  // 29
    ]

const listOfAwards: Text[] =
    [
        {
            id: "NBA Sixth Man of the Year",
            text: "Sixth Man of the Year"
        },
        {
            id: "All-Rookie Team",
            text: "All-Rookie Team"
        },
        {
            id: "All-NBA",
            text: "All-NBA"
        },
        {
            id: "NBA Most Valuable Player",
            text: "MVP"
        },
        {
            id: "All-Defensive Team",
            text: "All-Defensive Team"
        },
        {
            id: "Hall of Fame",
            text: "Hall of Fame"
        },
        {
            id: "same_team_career",
            text: "Played for Exactly 1 Franchise"
        },
        {
            id: "drafted_by_never_played",
            text: "Drafted by team, but never played for them"
        },
        {
            id: "top_ten_pick",
            text: "Top 10 \n Draft Pick"
        },
        {
            id: "5_years_with_team",
            text: "5+ Seasons \n with Team"
        },
        {
            id: "10_years",
            text: "10 season \nin the NBA"
        },
        {
            id: "5_different_teams",
            text: "played for\n 5+ different teams"
        },
        {
            id: "noPostseason",
            text: "Never Played \n in Playoffs"
        }
    ]

const listOfStats: Text[] =
    [
        {
            id: "1500pts_total",
            text: "1500+ Points\n(Season)"
        },
        {
            id: "200_3pts_season",
            text: "200+ 3pts\n(Season)"
        },
        {
            id: "25pts_pg",
            text: "25+ ppg\n(Season)"
        },
        {
            id: "2steals_pg",
            text: "2+ Steals per Game\n(Season)"
        },
        {
            id: "2blocks_pg",
            text: "2+ Blocks per Game\n(Season)"
        },
        {
            id: "82games",
            text: "Played 82 Games\n(Season)"
        },
        {
            id: "250blocks_season",
            text: "250+ Blocks\n(Season)"
        },
        {
            id: "700assists_season",
            text: "700+ Assists\n(Season)"
        },
        {
            id: "9assists_pg",
            text: "9+ Assists per Game\n(Season)"
        },
        {
            id: "12rebounds_pg",
            text: "12+ Rebounds per Game\n(Season)"
        },
        {
            id: "1500_3pts_career",
            text: "1500+ 3pts\n(Career)"
        },
        {
            id: "1000_blocks_career",
            text: "1000+ Blocks\n(Career)"
        },
        {
            id: "5000_assists_career",
            text: "5000+ Assists\n(Career)"
        },
        {
            id: "1500_steals_career",
            text: "1500+ Steals\n(Career)"
        },
        {
            id: "20000_points_career",
            text: "20,000+ Points\n(Career)"
        },
        {
            id: "scored_40_pts_playoffs",
            text: "40+ Point Game\n(Playoffs)"
        },
        {
            id: "scored_25_pts_finals",
            text: "25+ Point Game\n(Finals)"
        },
        {
            id: "5_pts_pg",
            text: "Under 5 ppg\n(Season)"
        },
        {
            id: "zero_3_pts",
            text: "Zero 3pt Attempts\n(Season)"
        },
        {
            id: "wins_over_55",
            text: "Won 55+ Games\n(Season)"
        },
        {
            id: "losses_over_55",
            text: "Lost 55+ Games\n(Season)"
        },
        {
            id: "15_pts_pg",
            text: "15+ ppg\n(Season)"
        },
        {
            id: "5_ast_pg",
            text: "5+ Assists per Game\n(Season)"
        },
        {
            id: "ft_below_500",
            text: "FT%\n under .500 \n(Season)"
        },
        {
            id: "ft_above_900",
            text: "Free Throw %\n over .900 \n(Season)"
        },
        {
            id: "pf_over_200",
            text: "200+ Personal Fouls\n(Season)"
        },
        {
            id: "ranked_top_30_PTS",
            text: "Top-30\n in Points\n(Season)"
        },
        {
            id: "ranked_top_30_FG3M",
            text: "Top-30 in\n 3-Pointers\n(Season)"
        },
        {
            id: "jersey_over_40",
            text: "Jersey #\n over 39"
        },
        {
            id: "jersey_over_30",
            text: "Jersey #\n over 29"
        },
        {
            id: "jersey_under_10",
            text: "Single Digit\n Jersey #"
        },
        {
            id: "jersey_in_30s",
            text: "Jersey #\nin the 30s"
        }

    ]

export const getLabel = (label_id: number) => {

    const indices: number[] = []

    let top_grid: gridData = grids[0];

    if (label_id !== 0) {
        console.log('checking')

        const getIndexById = (id: number): number => {
            const index = grids.findIndex((item: gridData) => item.id === label_id);
            return index;
        };

        top_grid = grids[getIndexById(label_id)];
    }

    const label: Labels = {
        id: top_grid.id,
        teams: [],
        awards: null,
        stats: null,
        awards_col2: null,
        stats_col2: null,
        college: null,
        teammate: null,
        coach: null
    }


    if (top_grid.college !== null && top_grid.college !== undefined) {

        label.college = listOfColleges[top_grid.college];
    }

    if (top_grid.teammate !== null && top_grid.teammate !== undefined) {

        label.teammate = listOfTeammates[top_grid.teammate];
    }

    if (top_grid.coach !== null && top_grid.coach !== undefined) {

        label.coach = listOfCoaches[top_grid.coach];
    }

    top_grid.teams.forEach((idx) => {
        label.teams.push(listOfTeams[idx]);
    });

    if (top_grid.awards) {
        label.awards = listOfAwards[top_grid.awards]
    }

    if (top_grid.stats) {
        label.stats = listOfStats[top_grid.stats]
    }

    if (top_grid.awards_col2) {
        label.awards_col2 = listOfAwards[top_grid.awards_col2]
    }

    if (top_grid.stats_col2) {
        label.stats_col2 = listOfStats[top_grid.stats_col2]
    }

    return label;

}

