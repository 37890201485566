import React, { useState } from "react";
import "./searchbar.css"
import { Player } from "./player";
import { NullableNumber, NullablePlayer, NullableNumberList } from "./App";
import { Labels } from "./getData";
import { checkPlayer } from "./checkPlayer";

var data = require("./data.json")

interface Props {
    getSearch(): void;
    players: NullablePlayer;
    selectedGrid: NullableNumber;
    labels: Labels;
    setPlayers(new_players: NullablePlayer): void;
    votes: NullableNumberList;
    setVotes(new_votes: NullableNumberList): void;
}


export const Searchbar: React.FC<Props> = ({ getSearch, players, selectedGrid, labels, setPlayers, votes, setVotes }) => {

    const [value, setValue] = useState('');

    const onChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setValue(event.target.value)
    }

    const onSearch = (searchTerm: string) => {
        console.log('search', searchTerm)
    }

    return (
        <div>
            <div className="overlay-full" onClick={getSearch}></div>
            <div className="overlay fade-in-element fade-out fade-out-element">
                <div className="flex border-blue-200 rounded button">
                    <input autoFocus
                        type="text"
                        value={value}
                        onChange={onChange}
                        className="block w-full px-4 py-2 text-black-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        placeholder="Search..."
                    />
                    {/* <button className="overlay-higher px-4 text-white bg-blue-600 border-l rounded" onClick={() => onSearch(value)}>
                        Submit
                    </button> */}
                </div>
                <div className="dropdown">
                    {data.filter((player: Player) => {
                        const searchTerm = value.toLowerCase();
                        const first_name = player.first_name.toLowerCase() + ' ' + player.last_name.toLowerCase();
                        const last_name = player.last_name.toLowerCase() + ' ' + player.first_name.toLowerCase();

                        return (searchTerm && first_name.startsWith(searchTerm)) || (searchTerm && last_name.startsWith(searchTerm));
                    }).sort((a: Player, b: Player) => {
                        const aEndYear = Number(a.end_year);
                        const bEndYear = Number(b.end_year);
                        return bEndYear - aEndYear;
                    }).slice(0, 10)
                        .map((player: Player) => (
                            <div className="flex border-blue-200 rounded button">

                                <div
                                    className="dropdown-row"
                                    key={player.id}
                                >
                                    {player.name}
                                    <span className="start-year">({player.start_year}-</span>
                                    <span className="end-year">{player.end_year})</span>
                                </div>

                                <button className="right px-1 text-white bg-blue-600 rounded" onClick={() => {
                                    onSearch(player.name)
                                    getSearch()
                                    if (selectedGrid !== null) {
                                        const newPlayers = checkPlayer(setVotes, votes, players, labels, player, selectedGrid)
                                        setPlayers(newPlayers)
                                    }
                                }}>
                                    Submit
                                </button>
                            </div>

                        ))}

                </div>
            </div>
        </div>
    );
};