import React, { useEffect, useState } from "react"
import "./App.css"
import { Analytics } from '@vercel/analytics/react'
import { Grid } from "./grid"
import { Searchbar } from "./searchbar"
import { getLabel } from "./getData"
import { Player } from "./player"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DropdownComponent } from "./dropdown"
import { getLocalPercentile } from "./percentiles"
import AdBanner from "./adBanner"
import { Modal } from "./instructions"

export type NullableNumber = number | null;
export type NullableNumberList = (number | null)[];
export type NullablePlayer = (Player | null)[];


function App() {
  const [team, setTeam] = useState(["Phillies"])
  const [search, setSearch] = useState(false)
  const [selectedGrid, setSelectedGrid] = useState<NullableNumber>(null)
  const [players, setPlayers] = useState<NullablePlayer>([null, null, null, null, null, null, null, null, null])
  const [votes, setVotes] = useState<NullableNumberList>([null, null, null, null, null, null, null, null, null])
  const [complete, setComplete] = useState(false)
  const [nullCount, countNulls] = useState(10)
  const [voteCount, countVote] = useState(0)
  const [tweet, setTweet] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [label_id, setLabelId] = useState(0)
  const [modal, setModal] = useState(false);

  const [percentile, setPercentile] = useState<NullableNumber>(null)

  const [completedPercentile, setCompletedPercentile] = useState(false)

  let lables = getLabel(label_id)

  const gridJSON = localStorage.getItem(lables.id.toString());

  let alreadyComplete = false;

  if (gridJSON) {
    const gridData = JSON.parse(gridJSON);

    alreadyComplete = gridData.alreadyComplete

  }

  // If players list is null, check if the data is stored in local storage
  if (players.every(element => element === null)) {

    if (gridJSON) {
      const gridData = JSON.parse(gridJSON);
      setPlayers(gridData.players)
      setVotes(gridData.votes)
      setPercentile(gridData.percentile)
    }
  }
  else {

    const gridData = {
      players: players,
      votes: votes,
      percentile: percentile,
      alreadyComplete: alreadyComplete
    }

    localStorage.setItem(lables.id.toString(), JSON.stringify(gridData));

  }

  let myNullCount = 0;
  let voteSum = 0;

  votes.forEach(value => {
    if (value !== null) {
      voteSum += value;
    }
  });

  votes.forEach(value => {
    if (value === null) {
      myNullCount++;
    }
  });

  if (myNullCount !== nullCount) {
    countNulls(myNullCount);
  }

  if (voteSum !== voteCount) {
    countVote(voteSum);
  }

  function getPercentile(grid_id: number, bucket: number): void {

    if (grid_id < 43) {
      const percentile = getLocalPercentile(grid_id, bucket)
      setPercentile(+percentile)
    }

    else {

      let response = null

      const percentile = `https://hoop-grids-api-endpoint.vercel.app/api/getPercentile?grid_id=${grid_id}&bucket=${bucket}`;

      fetch(percentile)
        .then(response => response.json())
        .then(data => {

          const percentile_pct = +data['percentile'] * 100
          const percentile_rounded = Number(percentile_pct.toFixed(2))
          response = percentile_rounded
          setPercentile(response)

        })
        .catch(error => {
          console.error('API request error:', error);
        });
    }

  }

  function getSquare(vote: number): string {
    if (vote < .1) { return '⬛' }
    else if (vote < 1) { return '🟪' }
    else if (vote < 5) { return '🟨' }
    else if (vote < 10) { return '⬜' }
    else if (vote < 15) { return '🟫' }
    else { return '🟩' }

  }



  const tweetText =
    '🔥🔥BANG🔥🔥\n🏀HoopGrids #' + lables.id + '🏀\n\n' + 'Rarity Score: ' + voteSum.toFixed(2) + '\n' + 'Percentile Rank: ' + percentile + '\n\n' +
    getSquare(votes[0]!) + getSquare(votes[1]!) + getSquare(votes[2]!) + '\n' +
    getSquare(votes[3]!) + getSquare(votes[4]!) + getSquare(votes[5]!) + '\n' +
    getSquare(votes[6]!) + getSquare(votes[7]!) + getSquare(votes[8]!) + '\n' +
    '\n' + '@hoopgrids / hoopgrids.com';


  const handleCopy = () => {
    // Perform any additional actions when the text is copied if needed
    console.log('Text copied to clipboard');
  };

  const handleTweet = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };

  const clearGrid = () => {
    localStorage.removeItem(lables.id.toString());
    setPlayers([null, null, null, null, null, null, null, null, null]);
    setVotes([null, null, null, null, null, null, null, null, null]);
    setPercentile(null);
    setComplete(false)
    setCompleted(false)
    setTweet(false)
    setCompletedPercentile(false)
    setPercentile(null)


  };


  useEffect(() => {
    // Perform any actions when the value changes
    console.log('Value changed:', votes);
    // Additional logic or function calls based on the value change
  }, [votes, percentile]); // Add the value as a dependency

  if (players.every((value) => value !== null) && completed === false) {
    setComplete(true)
    setTweet(true)
    setCompleted(true)
  }

  if (votes.every((value) => value !== null) && completedPercentile === false) {
    getPercentile(lables.id, Math.round(voteSum))
    setCompletedPercentile(true)
  }

  return (
    <div className="App">

      <div onClick={search ? () => {
        setSearch(false)
        setComplete(false)
      } : () => setComplete(false)}>

        <div className="container">
          <a href="https://twitter.com/HoopGrids"> <h1>HoopGrids.com</h1></a>

          {/* <a href="https://twitter.com/HoopGrids"><img className="twitter" src="twitter.png" /></a> */}
          <div className="far-right"><DropdownComponent updateLabelId={(newId: number) => setLabelId(newId)} setPlayers={(new_players) => { setPlayers(new_players) }} setVotes={(new_votes) => { setVotes(new_votes) }} setTweet={(tweet_show) => { setTweet(tweet_show) }} setCompleted={(completed_show) => { setCompleted(completed_show) }} setCompletedPercentile={(completed_percentiles_show) => { setCompletedPercentile(completed_percentiles_show) }} setPercentile={(percentiles_show) => { setPercentile(percentiles_show) }} /></div>


        </div>

        <Grid votes={votes} players={players} labels={lables} getSearch={() => { setSearch(!search) }} getSelectedGrid={(gridNumber: number) => { setSelectedGrid(gridNumber) }} />
      </div>


      <div>{complete && !alreadyComplete ? <div className="overlay-full" onClick={() => {

        
        setComplete(false)

        const gridData = {
          players: players,
          votes: votes,
          percentile: percentile,
          alreadyComplete: true
        }

        localStorage.setItem(lables.id.toString(), JSON.stringify(gridData));

        


      }



      } ><img className="complete fade-in-element" src="complete.png" /></div> : null
      }
      </div>





      <div> {search && !completed ? (
        <Searchbar votes={votes} setVotes={(new_votes) => { setVotes(new_votes) }} labels={lables} getSearch={() => { setSearch(!search) }} players={players} selectedGrid={selectedGrid} setPlayers={(new_players) => { setPlayers(new_players) }} />
      ) : null}
      </div>




      {percentile ?
        <div>
          <br></br>
          <p className="percentile not-bold">Your score is better than <span className="bold">{percentile}%</span> of grids</p>
          <div className="center">
            <span style={{ display: 'flex' }}>
              <button onClick={clearGrid} style={{marginRight: "25px"}} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1.5 px-4 rounded-full">Clear Grid</button>
              <CopyToClipboard text={tweetText} onCopy={handleCopy}>
                <button onClick={handleTweet} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1.5 px-4 rounded-full">Tweet score</button>
              </CopyToClipboard>
            </span>
          </div></div> : (
          <div>
            <h3 onClick={() => setModal(!modal)} style={{ cursor: 'pointer' }}><u>how to play</u></h3>
            {modal ? (<Modal setModal={() => { setModal(!modal) }} />) : null}

          </div>)} 

      <Analytics />

   
            <AdBanner />
     

      <div style={{ display: 'none' }}>NBA Basketball Trivia Sports Game. Hoops, All-Star, Assists, Rebounds, Points, Defense, Block, Foul, Jumpshot, </div>
      <div style={{ display: 'none' }}>LeBron James, Kevin Durant, Lakers, Celtics, Suns, Knicks, Steals, Playoffs, Coach, Dribble, Three-pointer, Draft</div>

      <div style={{ display: 'none' }}>Atlanta Hawks, Boston Celtics, Brooklyn Nets, Charlotte Hornets, Chicago Bulls, Cleveland Cavaliers, Dallas Mavericks,
        Denver Nuggets, Detroit Pistons, Golden State Warriors, Houston Rockets, Indiana Pacers, LA Clippers, Los Angeles Lakers, Memphis Grizzlies, Miami Heat,
        Milwaukee Bucks, Minnesota Timberwolves, New Orleans Pelicans, New York Knicks, Oklahoma City Thunder, Orlando Magic, Philadelphia 76ers, Phoenix Suns,
        Portland Trail Blazers, Sacramento Kings, San Antonio Spurs, Toronto Raptors, Utah Jazz, Washington Wizards
      </div>

    </div >
  )
}

export default App







