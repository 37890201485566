import React, { useState, useRef } from "react";
import "./instructions.css"


interface Props {
    setModal(): void;
}

export const Modal: React.FC<Props> = ({ setModal }) => {


    return (
        <div>
            <div className="overlay-full" onClick={setModal}></div>
            <div style={{ borderRadius: '10px' }} className="fade-in-element fade-out fade-out-element instructions">
                <button className="close-button" onClick={setModal}>X</button>
                <p className="title">How To Play</p>

                <div style={{ maxWidth: '350px' }} className="scrollable">
                    <p className="body">Select a player for each cell that matches the criteria for that cell's row and column.</p>
                    <p className="body">There is no limit to the number of guesses.</p>
                    <p className="body">If a player has been submitted for a grid, you can resubmit
                        a different player by clicking the cell again. Once the grid has been completely filled, you cannot resubmit any players.</p>

                    <br></br>

                    <p className="sub-title">Criteria Qualifications</p>
                    <p className="body">Select a player for each cell that matches the criteria for that cell's row and column.</p>
                    
                    <p className="sub-sub-title">Team & Team</p>
                    <div className="centered-images">
                        <img style={{ maxWidth: '80px', marginRight: '0px' }} src="./sixers.png"/>
                        <img style={{ maxWidth: '80px', marginLeft: '0px' }} src="./suns.png" />
                    </div>
                    <p className="body">For a player to qualify for a 'Team & Team' cell, that player must have recorded at least 1 
                    Regular Season Game with each team.</p>
                    <p className="body">It does not have to be the same season.</p>

                    <p className="sub-sub-title">Teammate/Coach & Team</p>
                    <div className="centered-images">
                        <img style={{ maxWidth: '80px', marginRight: '0px', marginTop: '10px'}} src="./ish.png"/>
                        <img style={{ maxWidth: '80px', marginLeft: '0px' }} src="./suns.png" />
                    </div>
                    <p className="body">For a player to qualify for a 'Teammate/Coach & Team' cell, that player must have recorded at least 1 
                    Regular Season Game with the team, and 1 Regulare Season Game with the teammate/coach.</p>
                    <p className="body">It does not have to be the same season.</p>

                    <p className="sub-sub-title">Teammate/Coach & Stat/Accolade</p>
                    <div className="centered-images">
                        <img style={{ maxWidth: '80px', marginRight: '0px', marginTop: '10px'}} src="./ish.png"/>
                        <img style={{ maxWidth: '80px', marginLeft: '0px' }} src="./stat.png" />
                    </div>

                    <p className="body">For a player to qualify for a 'Teammate/Coach & Stat/Accolade' cell, that player must have recorded at least 1 
                    Regular Season Game with the teammate/coach and qualify for the stat/accolade.</p>
                    <p className="body">It does <b>not</b> have to be the same season regardless if the Stat/Accolade is Career or Season.</p>


                    <p className="sub-sub-title">Stat/Accolade & Team</p>
                    <div className="centered-images">
                        <img style={{ maxWidth: '80px', marginRight: '0px', marginTop: '10px'}} src="./stat.png"/>
                        <img style={{ maxWidth: '80px', marginLeft: '0px' }} src="./suns.png" />
                    </div>

                    <p className="body">If the stat/accolade is based on <b>Season</b>, they must qualify in the <b>same </b> 
                    season as playing for the team.</p>
                    <p className="body">If the stat/accolade is based on <b>Career</b>, they do not have to qualify in the same 
                    season as playing for the team.</p>

                    <br></br>

                    <p className="sub-title">Rarity Colors</p>
                    <p className="body">The color behind player images is related to how rare they are. The color scales are shown below:</p>
                    <div className="image-with-text">
                        <img style={{ marginLeft: '50px', marginRight: '10px', width: '70px' }} src="./color1.png" />
                        <p className="sub-body">Bronze<br /><span style={{ fontSize: '10px' }}>10 - 15%</span></p>

                    </div>
                    <br></br>
                    <div className="image-with-text">
                        <img style={{ marginLeft: '50px', marginRight: '10px', width: '70px' }} src="./color2.png" />
                        <p className="sub-body">Silver<br /><span style={{ fontSize: '10px' }}>5 - 10%</span></p>

                    </div>
                    <br></br>
                    <div className="image-with-text">
                        <img style={{ marginLeft: '50px', marginRight: '10px', width: '70px' }} src="./color3.png" />
                        <p className="sub-body">Gold<br /><span style={{ fontSize: '10px' }}>1 - 5%</span></p>

                    </div>
                    <br></br>
                    <div className="image-with-text">
                        <img style={{ marginLeft: '50px', marginRight: '10px', width: '70px' }} src="./color4.png" />
                        <p className="sub-body">Unicorn<br /><span style={{ fontSize: '10px' }}>0 - 1%</span></p>

                    </div>
                    <br></br>


                    <p className="sub-title">Rarity Score</p>
                    <p className="body">Rarity Score is calculated by summing up each percentage from every submitted player.
                        Lower Rarity Scores equate to a rarer grid.</p>



                </div>

            </div>

        </div>
    );
};