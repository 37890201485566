import React, { useState } from "react";
import { gridData } from "./getData";


import "./dropdown.css"
import { NullableNumberList, NullablePlayer } from "./App";

interface Props {
    updateLabelId(label_id: number): void;
    setPlayers(new_players: NullablePlayer): void;
    setVotes(new_votes: NullableNumberList): void;
    setTweet(tweet_show: boolean): void;
    setCompleted(completed_show: boolean): void;
    setCompletedPercentile(completed_show: boolean): void;
    setPercentile(completed_show: null): void;
}

var data = require("./grids.json")

export const DropdownComponent: React.FC<Props> = ({ updateLabelId, setPlayers, setVotes, setTweet, setCompleted, setCompletedPercentile, setPercentile }) => {
    const [isOpen, setOpen] = useState(false);
    const [gridsText, setGridsText] = useState('grids');

    return (
        <div>
            <button
                className="btn btn-blue change-team inline-flex items-center"
                onClick={() => setOpen((!isOpen))}
            >
                {gridsText}
                <svg
                    className="ml-1 w-4.5 h-4"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    ></path>
                </svg>
            </button>
            <div
                id="dropdown"
                className={` ${isOpen ? "block" : "hidden"}`}
            >

                <ul className="h-80 overflow-y-auto w-38 dropdown-item dropdown-menu bg-white rounded divide-gray-100 shadow ">

                    {data.map((row: gridData) => {
                        return (
                            <li
                                key={row.id}
                                onClick={() => null}>
                                <a href="#" className="block px-1 py-1 hover:bg-gray-100" onClick={() => {
                                    updateLabelId(row.id)
                                    setPlayers([null, null, null, null, null, null, null, null, null])
                                    setVotes([null, null, null, null, null, null, null, null, null])
                                    setTweet(false)
                                    setCompleted(false)
                                    setCompletedPercentile(false)
                                    setOpen(false)
                                    setPercentile(null)

                                    if (row.date === "Today") { setGridsText(`Today `) }
                                    else {
                                        const [day, month] = row.date.split("-").slice(0, 2)
                                        setGridsText(`${day}-${month} `)
                                    }

                                }

                                }>
                                    <h6>{row.date}</h6>
                                </a>
                            </li>
                        )
                    })
                    }

                </ul>
            </div>
        </div>
    );
};