var grid_rarities = require("./rarity.json");



export const getLocalRarity = (grid_id: number, grid_pos_id: number, player_id: string) => {

    //const grid_rarity: gridRarity = grid_rarities;

    let count = 1

    if (player_id in grid_rarities[grid_id.toString()][grid_pos_id.toString()]){

        count = grid_rarities[grid_id.toString()][grid_pos_id.toString()][player_id]


    }  
    const total = grid_rarities[grid_id.toString()][grid_pos_id.toString()]["Total"]

    const guess_pct = count/total*100

    const guess = Number(guess_pct.toFixed(2))

    return guess

}