export const logoLinks = {
  bos:     "https://loodibee.com/wp-content/uploads/nba-boston-celtics-logo-300x300.png",
  atl:      "https://loodibee.com/wp-content/uploads/nba-atlanta-hawks-logo-300x300.png",
  bkn:    "https://loodibee.com/wp-content/uploads/nba-brooklyn-nets-logo-300x300.png",
  cha:     "https://loodibee.com/wp-content/uploads/nba-charlotte-hornets-logo-300x300.png",
  chi:  "https://loodibee.com/wp-content/uploads/nba-chicago-bulls-logo-300x300.png",
  cle:      "https://loodibee.com/wp-content/uploads/nba-cleveland-cavaliers-logo-300x300.png",
  dal:   "https://loodibee.com/wp-content/uploads/nba-dallas-mavericks-logo-300x300.png",
  den:    "https://loodibee.com/wp-content/uploads/nba-denver-nuggets-logo-2018-300x300.png",
  det:     "https://loodibee.com/wp-content/uploads/nba-detroit-pistons-logo-300x300.png",
  gsw:     "https://loodibee.com/wp-content/uploads/nba-golden-state-warriors-logo-300x300.png",
  hou:     "https://loodibee.com/wp-content/uploads/houston-rockets-logo-symbol.png",
  ind:     "https://loodibee.com/wp-content/uploads/indiana-pacers-logo-symbol-300x300.png",
  lac:     "https://loodibee.com/wp-content/uploads/los-angeles-clippers-logo-symbol-300x300.png",
  lal:     "https://loodibee.com/wp-content/uploads/nba-los-angeles-lakers-logo-300x300.png",
  mem:     "https://loodibee.com/wp-content/uploads/nba-memphis-grizzlies-logo-300x300.png",
  mia:     "https://loodibee.com/wp-content/uploads/miami-heat-logo-symbol-300x300.png",
  mil:     "https://loodibee.com/wp-content/uploads/milwaukee-bucks-logo-symbol-300x300.png",
  min:     "https://loodibee.com/wp-content/uploads/nba-minnesota-timberwolves-logo-300x300.png",
  nop:     "https://loodibee.com/wp-content/uploads/nba-new-orleans-pelicans-logo-300x300.png",
  nyk:     "https://loodibee.com/wp-content/uploads/nba-new-york-knicks-logo-300x300.png",
  okc:     "https://loodibee.com/wp-content/uploads/oklahoma-city-thunder-logo-symbol-300x300.png",
  orl:     "https://loodibee.com/wp-content/uploads/orlando-magic-logo-symbol-300x300.png",
  phi:     "https://loodibee.com/wp-content/uploads/philadelphia-76ers-1963-1977-300x300.png",
  phx:     "https://loodibee.com/wp-content/uploads/phoenix-suns-logo-symbol-300x300.png",
  por:     "https://loodibee.com/wp-content/uploads/portland-trail-blazers-logo-symbol-300x300.png",
  sac:     "https://loodibee.com/wp-content/uploads/nba-sacramento-kings-logo-300x300.png",
  sas:     "https://loodibee.com/wp-content/uploads/san-antonio-spurs-logo-symbol-300x300.png",
  tor:     "https://loodibee.com/wp-content/uploads/nba-toronto-raptors-logo-300x300.png",
  uta:     "https://loodibee.com/wp-content/uploads/utah-jazz-logo-symbol-300x300.png",
  was:     "https://loodibee.com/wp-content/uploads/washington-wizards-logo-symbol-300x300.png",
};

export const collegeLinks = {
  duke:     "https://loodibee.com/wp-content/uploads/Duke_Blue_Devils_logo-300x300.png",
  kansas:      "https://loodibee.com/wp-content/uploads/Kansas_Jayhawks_logo-300x300.png",
  kentucky:    "https://loodibee.com/wp-content/uploads/Kentucky_Wildcats_logo-300x300.png",
  unc:     "https://loodibee.com/wp-content/uploads/North_Carolina_Tar_Heels_logo-300x300.png"
};