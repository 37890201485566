import { Component, useReducer } from "react";
import { NullableNumber, NullablePlayer, NullableNumberList } from "./App";
import { Labels } from "./getData";
import { Player } from "./player";
import { getLocalRarity } from "./rarity";






function getVote(grid_id: number, grid_pos_id: number, player_id: string, votes: NullableNumberList, setVotes: (new_votes: NullableNumberList) => void): void {

    if (grid_id < 43) {

        const guess = getLocalRarity(grid_id, grid_pos_id, player_id)

        votes[grid_pos_id] = guess

        setVotes(votes);


    }
    else {


        const url = `https://hoop-grids-api-endpoint.vercel.app/api/getData?grid_id=${grid_id}&grid_pos_id=${grid_pos_id}&player_id=${player_id}`;
        const total_url = `https://hoop-grids-api-endpoint.vercel.app/api/getTotal?grid_id=${grid_id}&grid_pos_id=${grid_pos_id}`;


        Promise.all([fetch(url), fetch(total_url)])
            .then(responses => {
                const [response1, response2] = responses;
                return Promise.all([response1.json(), response2.json()]);
            })
            .then(data => {
                const [data1, data2] = data;
                // Process the data from both APIs here

                const my_votes = data1['data']['votes'];
                const total_votes = data2['data']['votes'];

                const guess_pct = +my_votes / +total_votes * 80

                const guess = Number(guess_pct.toFixed(2))

                votes[grid_pos_id] = guess

                setVotes(votes);
            })
            .catch(error => {
                console.error('API request error:', error);
            });
    }


    // const fetchData = () => {
    //     fetch(url)
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(jsonData => {
    //             votes[grid_pos_id] = jsonData['data']['votes'];
    //             setVotes(votes);
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         }).finally(()=>{setVotes(votes)});
    // }

    // fetchData();
}

//     try {
//         const response = await fetch(url);
//         const data = await response.json();

//         const total_response = await fetch(total_url);
//         const total_data = await total_response.json();

//         const guess_pct = +data.data["votes"]/+total_data.data["votes"]*100
//         const guess = Number(guess_pct.toFixed(2))

//       if (data.success && total_data.success) {

//         votes[grid_pos_id] = guess

//         setVotes(votes)

//         return votes;

//       } else {
//         console.error(data.message);
//         return null;
//       }
//     } catch (error) {
//       console.error('Request error', error);
//       return null;
//     }
//   }


export const checkPlayer = (setVotes: (new_votes: NullableNumberList) => void, votes: NullableNumberList, players: NullablePlayer, labels: Labels, player: Player, selectedGrid: number) => {

    if (player.image) {
        player.image_link = 'https://cdn.nba.com/headshots/nba/latest/260x190/' + player.id + '.png'
    }
    else {
        player.image_link = 'https://cdn.nba.com/headshots/nba/latest/260x190/fallback.png'
    }

    const teamGrids: number[] = [0, 1, 3, 4, 6, 7];
    const categoryGrids: number[] = [2, 5, 8];

    const existingPlayer = players.find((p) => p !== null && p.id === player.id);

    if (existingPlayer) {

        return players;
    }

    if (teamGrids.includes(selectedGrid)) {
        if (selectedGrid === 0) {
            if (player.teams.includes(labels.teams[0].toUpperCase()) && player.teams.includes(labels.teams[2].toUpperCase())) {

                getVote(labels.id, 0, player.id, votes, setVotes);

                players[selectedGrid] = player;

            }
        }
        if (selectedGrid === 1) {

            if (labels.stats_col2 !== null) {
                if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].includes(labels.teams[2].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 1, player.id, votes, setVotes)
                }
            }

            else if (labels.awards_col2 !== null) {
                if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].includes(labels.teams[2].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 1, player.id, votes, setVotes)
                }
            }

            else {
                if (player.teams.includes(labels.teams[1].toUpperCase()) && player.teams.includes(labels.teams[2].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 1, player.id, votes, setVotes)
                }
            }
        }
        if (selectedGrid === 3) {


            if (player.teams.includes(labels.teams[0].toUpperCase()) && player.teams.includes(labels.teams[3].toUpperCase())) {
                players[selectedGrid] = player;

                getVote(labels.id, 3, player.id, votes, setVotes)
            }
        }
        if (selectedGrid === 4) {

            if (labels.stats_col2 !== null) {
                if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].includes(labels.teams[3].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 4, player.id, votes, setVotes)
                }
            }

            else if (labels.awards_col2 !== null) {
                if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].includes(labels.teams[3].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 4, player.id, votes, setVotes)
                }
            }
            else {
                if (player.teams.includes(labels.teams[1].toUpperCase()) && player.teams.includes(labels.teams[3].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 4, player.id, votes, setVotes)
                }
            }
        }
        if (selectedGrid === 6) {

            if (labels.college !== null) {
                if (player.teams.includes(labels.teams[0].toUpperCase()) && player.college === labels.college.text) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 6, player.id, votes, setVotes)
                }
            }
            else if (labels.teammate !== null) {
                if (player.teams.includes(labels.teams[0].toUpperCase()) && player.teammates.includes(labels.teammate.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 6, player.id, votes, setVotes)
                }
            }
            else if (labels.coach !== null) {
                if (player.teams.includes(labels.teams[0].toUpperCase()) && player.coaches.includes(labels.coach.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 6, player.id, votes, setVotes)
                }
            }
            else {
                if (player.teams.includes(labels.teams[0].toUpperCase()) && player.teams.includes(labels.teams[4].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 6, player.id, votes, setVotes)
                }
            }
        }
        if (selectedGrid === 7) {

            if (labels.college !== null) {

                if (labels.stats_col2 !== null) {
                    if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].length > 0 && player.college === labels.college.text) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else if (labels.awards_col2 !== null) {
                    if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].length > 0 && player.college === labels.college.text) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else {
                    if (player.teams.includes(labels.teams[1].toUpperCase()) && player.college === labels.college.text) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }
            }
            else if (labels.teammate !== null) {

                if (labels.stats_col2 !== null) {
                    if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].length > 0 && player.teammates.includes(labels.teammate.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else if (labels.awards_col2 !== null) {
                    if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].length > 0 && player.teammates.includes(labels.teammate.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }
                else {
                    if (player.teams.includes(labels.teams[1].toUpperCase()) && player.teammates.includes(labels.teammate.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

            }
            else if (labels.coach !== null) {

                if (labels.stats_col2 !== null) {
                    if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].length > 0 && player.coaches.includes(labels.coach.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else if (labels.awards_col2 !== null) {
                    if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].length > 0 && player.coaches.includes(labels.coach.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else {
                    if (player.teams.includes(labels.teams[1].toUpperCase()) && player.coaches.includes(labels.coach.id)) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }
            }
            else {

                if (labels.stats_col2 !== null) {
                    if (labels.stats_col2 !== null && player.stats[labels.stats_col2.id].includes(labels.teams[4].toUpperCase())) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else if (labels.awards_col2 !== null) {
                    if (labels.awards_col2 !== null && player.awards[labels.awards_col2.id].includes(labels.teams[4].toUpperCase())) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }

                else {
                    if (player.teams.includes(labels.teams[1].toUpperCase()) && player.teams.includes(labels.teams[4].toUpperCase())) {
                        players[selectedGrid] = player;
                        getVote(labels.id, 7, player.id, votes, setVotes)
                    }
                }
            }

        }
    }





    else if (categoryGrids.includes(selectedGrid)) {

        if (selectedGrid === 2) {

            if (labels.awards !== null && player.awards[labels.awards.id].includes(labels.teams[2].toUpperCase())) {
                players[selectedGrid] = player;
                getVote(labels.id, 2, player.id, votes, setVotes)
            }
            else if (labels.stats && player.stats[labels.stats.id].includes(labels.teams[2].toUpperCase())) {
                players[selectedGrid] = player;
                getVote(labels.id, 2, player.id, votes, setVotes)
            }
        }
        if (selectedGrid === 5) {

            if (labels.awards !== null && player.awards[labels.awards.id].includes(labels.teams[3].toUpperCase())) {
                players[selectedGrid] = player;
                getVote(labels.id, 5, player.id, votes, setVotes)
            }
            else if (labels.stats && player.stats[labels.stats.id].includes(labels.teams[3].toUpperCase())) {
                players[selectedGrid] = player;
                getVote(labels.id, 5, player.id, votes, setVotes)
            }
        }

        if (selectedGrid === 8) {

            if (labels.college !== null) {
                if (labels.awards !== null && player.awards[labels.awards.id].length > 0 && player.college === labels.college.text) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
                else if (labels.stats && player.stats[labels.stats.id].length > 0 && player.college === labels.college.text) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
            }
            else if (labels.teammate !== null) {

                if (labels.awards !== null && player.awards[labels.awards.id].length > 0 && player.teammates.includes(labels.teammate.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
                else if (labels.stats && player.stats[labels.stats.id].length > 0 && player.teammates.includes(labels.teammate.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
            }

            else if (labels.coach !== null) {

                if (labels.awards !== null && player.awards[labels.awards.id].length > 0 && player.coaches.includes(labels.coach.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
                else if (labels.stats && player.stats[labels.stats.id].length > 0 && player.coaches.includes(labels.coach.id)) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
            }

            else {
                if (labels.awards !== null && player.awards[labels.awards.id].includes(labels.teams[4].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
                else if (labels.stats && player.stats[labels.stats.id].includes(labels.teams[4].toUpperCase())) {
                    players[selectedGrid] = player;
                    getVote(labels.id, 8, player.id, votes, setVotes)
                }
            }

        }


    }

    return players;

}

