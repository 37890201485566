var grids_percentiles = require("./percentiles.json");

interface gridPercentiles {
    [index: string]: string[];
}

export const getLocalPercentile = (grid_id: number, votes: number) => {

    const percentiles: gridPercentiles = grids_percentiles;

    const index: number = grid_id + 1;

    const percentileList = percentiles[index.toString()]

    return percentileList[votes]

}